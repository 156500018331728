<script lang="ts" setup>
import {
  useRouter,
} from 'vue-router';
import { toRef } from 'vue';

import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import {
  AllGamesGroupUrl,
  EgsJackpotsSyncStateTimerName,
} from 'web/src/modules/egs/constants';
import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import type { EgsGame } from 'web/src/modules/egs/types';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import EgsGameJackpotListItem from 'web/src/modules/egs/submodules/jackpots/components/EgsGameJackpotListItem/EgsGameJackpotListItem.vue';
import { useComponentSyncState } from 'web/src/modules/core/composables';

const router = useRouter();

const casinoTopStore = useCasinoTopStore();
const analytics = useAnalytics();
const navigation = useEgsNavigation(router);

const hasJackpots = toRef(casinoTopStore, 'hasJackpots');
const jackpots = toRef(casinoTopStore, 'jackpots');

function getJackpotLocation(game: EgsGame): string {
  return navigation.getEgsGameHref(game);
}

function navigateToJackpot(game: EgsGame): void {
  analytics.push(AnalyticsEvent.Z_CLICK_JACKPOT_BANNER, {});
  void navigation.navigateToGamePage(game);
}

const { timeout } = useComponentSyncState(casinoTopStore.updateJackpots, EgsJackpotsSyncStateTimerName, {
  condition: hasJackpots,
});
</script>

<template>
  <div v-auto-id="'CasinoHomeJackpotsBlock'"
    v-if="hasJackpots && jackpots"
    v-data-test="{ el: 'home-jackpots' }"
  >
    <HomeHeading
      :class="$style['home-jackpots__heading']"
      :title="$t('WEB2_HOME_HEADING_JACKPOTS')"
      :button-text="$t('WEB2_HOME_HEADING_JACKPOTS_BTN')"
      :to="{
        name: CasinoRouteName.CASINO_LOBBY,
        params: {
          groupUrl: AllGamesGroupUrl,
          categoryUrl: 'jackpots',
        },
      }"
      is-dark
    />
    <VSwiper
      is-scroll-snap-enabled
      :wrapper-class="$style['home-jackpots__swiper-wrapper']"
    >
      <VSwiperSlide
        v-for="jackpot in jackpots"
        :key="jackpot.id"
        v-collect:click-counter="{ SLOTS: 'jackpotBanner'}"
        @click="navigateToJackpot(jackpot)"
      >
        <EgsGameJackpotListItem
          :item="jackpot"
          :href="getJackpotLocation(jackpot)"
          :animation-duration="timeout"
        />
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .home-jackpots {
    &__heading {
      @if not $isDesktop {
        padding-right: 8px;
      }
    }

    &__swiper-wrapper {
      gap: 16px;

      @if not $isDesktop {
        padding: 0 8px;
      }
    }
  }
}
</style>
